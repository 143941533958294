/* You can add global styles to this file, and also import other style files */
@import "styles/variables";
@import "@fd/core/styles";
@import "styles/colors";

@import "../node_modules/font-awesome/scss/font-awesome.scss";

html,
body {
  height: 100%;
  min-width: 320px; // smallest screen size
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.fd-popover {
  &.mat-menu-panel {
    max-width: calc(100vw - 48px);
    min-height: auto;

    .mat-menu-content {
      padding: 0 !important;

      .mat-menu-item {
        line-height: inherit;
      }
    }
  }
}

.no-gutters {
  .mat-expansion-panel-content > .mat-expansion-panel-body {
    padding: 0 !important;
  }
}

pre {
  &.code {
    background-color: black;
    color: white;
    padding: 0.5rem;
    border-radius: 4px;
  }
}

.mat-primary-lighter {
  @at-root mat-toolbar#{&} {
    background-color: var(--color-primary-lighter);
    color: #fff;
  }
}

.-mt-1 {
  margin-top: -1rem !important;
}

.-me-1 {
  margin-right: -1rem !important;
}

.-mb-1 {
  margin-bottom: -1rem !important;
}

.-ms-1 {
  margin-left: -1rem !important;
}

mat-checkbox[disabled]:not(.mat-checkbox-disabled) {
  pointer-events: none;
}

mat-slide-toggle[disabled]:not(.mat-disabled) {
  pointer-events: none;
}

mat-toolbar.secondary {
  background: inherit;
  height: auto;
  padding-top: 0.25rem;
}

// fixes 'Material Icons' font-family issue
mat-icon .mat-badge-content {
  font-family: "Roboto";
}

/* make input letter spacing consistent */
mat-form-field.mat-form-field {
  letter-spacing: 1px;

  .mat-form-field-label {
    letter-spacing: 1.5px;
  }
}

input {
  letter-spacing: inherit;
}

.pointer {
  cursor: pointer;
}

.hidden-arrow {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.mat-paginator-sticky {
  left: 0px;
  bottom: 0px;
  position: sticky;
  z-index: 10;
  background-color: white;
  overflow: auto;
  padding: 5px;
}

.text-primary {
  color: var(--color-primary) !important;
}

.border-primary {
  border-color: var(--color-primary) !important;
}

th,
td {
  white-space: nowrap;
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.table-container {
  max-height: calc(100vh - 240px);
  max-width: 100%;
  overflow: auto;
}

.small-icon {
  font-size: 14px;
}

.medium-icon {
  transform: scale(0.9) !important;
  vertical-align: middle;
}

.list-unstyled {
  list-style-type: none;
}

body mat-custom-header .mat-custom-controls .mat-custom-date-monthday,
body mat-custom-header.type-date .mat-custom-controls .mat-custom-date-year,
body mat-custom-header.type-time .mat-custom-controls .mat-custom-time-hour,
body mat-custom-header.type-time .mat-custom-controls .mat-custom-time-minute {
  font-size: 28px;
  color: white;
}
body mat-custom-header.type-time .mat-custom-controls .mat-custom-time-ampm {
  margin: -1em 0;
  .mat-calendar-control {
    color: white;
    height: 28px;
  }
}
.mat-calendar-next-button:after {
  border-right-width: 2px;
  transform: translate(-2px) rotate(45deg);
}
.mat-calendar-previous-button:after {
  border-left-width: 2px;
  transform: translate(2px) rotate(-45deg);
}

.mat-calendar-previous-button:after,
.mat-calendar-next-button:after {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  content: "";
  margin: 15.5px;
  border: 0 solid currentColor;
  border-top-width: 2px;
}
.mat-calendar-previous-button,
.mat-calendar-next-button {
  padding: 0;
  min-width: 0;
  width: 40px;
  height: 40px;
  flex-shrink: 0;
  line-height: 40px;

  &:not(:active) {
    background: none;
  }
}

body .mdc-switch {
  padding-top: 0.5em;
  padding-bottom: 0.5em;

  .mdc-switch__icons {
    display: none;
  }
}

body [mat-dialog-title] {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0;
  }
}

.mat-mdc-no-data-row .mat-cell {
  padding: 0.5em 0;
}

.tooltip-multiline {
  white-space: pre-line;
}

.alert-info {
  background-color: lighten(#ff9800, 10%);
  color: var(--mtx-progress-warning-text-color);
  border-color:  var(--mtx-progress-warning-indicator-color);
}

highcharts-chart { 
  display: block; 
  width: 100%; 
  height: auto;
}