// Additional colors not part of Angular Material
@use "sass:map";

@mixin standard-elements($color-name, $color, $contrast-color) {
  .mdc-button,
  .mat-mdc-icon-button,
  .mat-mdc-stroked-button {
    &.mat-#{$color-name} {
      background: transparent;
      color: #{$color};
    }
  }

  .mat-mdc-raised-button,
  .mat-mdc-flat-button,
  .mat-mdc-fab,
  .mat-mdc-mini-fab {
    &.mat-#{$color-name} {
      background: #{$color};
      color: #{$contrast-color};
    }
  }

  .mat-mdc-chip.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-#{$color-name} {
    background: #{$color};
    color: #{$contrast-color};
  }
}

@mixin extended-elements($color-name, $color, $contrast-color) {
  mat-button-toggle {
    &[color="#{$color-name}"] {
      color: #{$color};

      &.mat-button-toggle-checked {
        color: #{$contrast-color};
        background-color: #{$color};

        .mat-button-toggle-focus-overlay {
          border-bottom: 48px;
        }
      }
    }
  }

  mat-nav-list,
  mat-list {
    [mat-list-item] {
      &[color="#{$color-name}"] {
        color: #{$color} !important;
      }

      &.isActive {
        background-color: rgba(44, 44, 44, 0.04);
      }
    }
  }
}

@include standard-elements("success", map.get($colors, success), #fff);
@include extended-elements("success", map.get($colors, success), #fff);

.bg-danger {
  background-color: #dc3545 !important;
}

.text-danger {
  color: #dc3545 !important;
}

.bg-info {
  background-color: #0dcaf0 !important;
}

.text-info {
  color: #0dcaf0 !important;
}

.bg-success {
  background-color: #198754 !important;
}

.text-success {
  color: #198754 !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.text-warning {
  color: #ffc107 !important;
}
